@charset "UTF-8";
:root {
  --fc-border-color: #ababab !important;
}

* {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

p {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #363636;
}

html {
  font-size: 62.5%;
}

.general-container {
  padding-top: 100px;
  min-height: 100vh;
}

.section-title {
  font-size: 3.5rem;
  margin: 2rem 0 1rem 0;
  opacity: 0.9;
}

.container {
  height: 100%;
  width: 90%;
  min-width: 270px;
  max-width: 1500px;
  margin: 0 auto;
}

.container-y-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-fluid {
  width: 100%;
}

.App {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: #F5EBEA;
}

@keyframes leftEntranceOpacity {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rightEntranceOpacity {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes growEntranceOpacity {
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
.initialOpacity {
  opacity: 0;
}

@keyframes entranceOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.initialOpacity {
  opacity: 0;
}

.growEntranceOpacity {
  animation-name: growEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.entranceOpacity {
  animation-name: entranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.leftEntranceOpacity {
  animation-name: leftEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.rightEntranceOpacity {
  animation-name: rightEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

/* INPUTS */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input {
  border: none;
  width: 100%;
  font-size: 1.6rem;
  padding: 12px 12px 12px 24px;
  border-radius: 20px;
  color: #363636;
}

input::-moz-placeholder {
  color: #afafaf;
}

input::placeholder {
  color: #afafaf;
}

.input-error input,
.input-error select,
.input-error textarea {
  outline: 1px solid #C55556 !important;
  margin-bottom: 2px !important;
}
.input-error p {
  margin-bottom: 10px;
  margin-left: 12px;
}

.input-success input,
.input-success select,
.input-success textarea {
  outline: 1px solid rgb(70, 165, 98) !important;
}

.input-disabled input:-webkit-autofill,
.input-disabled input:-webkit-autofill:hover,
.input-disabled input:-webkit-autofill:focus,
.input-disabled input:-webkit-autofill:active,
.input-disabled input,
.input-disabled textarea {
  -webkit-box-shadow: 0 0 0 30px rgb(233, 233, 233) inset;
  outline: 1px solid rgb(100, 100, 100) !important;
  background-color: rgba(192, 192, 192, 0.363) !important;
  opacity: 0.75;
}

.input-toggle-visibility-container {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.formLogin__input-error-text {
  font-size: 1.5rem;
  color: #C55556;
}

.cruz-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* Textarea */
textarea {
  border: none;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 12px 12px 12px 24px;
  border-radius: 20px 20px 0 20px;
  font-size: 1.6rem;
  color: #363636;
}
textarea:focus {
  -webkit-box-shadow: 0 0 0 30px rgb(233, 233, 233) inset;
  outline: 1px solid rgba(0, 0, 0, 0.178);
  background-color: rgba(192, 192, 192, 0.363) !important;
  opacity: 0.75;
}

/* MARGENES */
.mb-1 {
  margin-bottom: 0.6rem;
}

.mb-2 {
  margin-bottom: 1.4rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-2 {
  margin-top: 1.4rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

/* IMPUTS RADIO */
input[type=radio] {
  height: 14px;
  width: 14px;
}

input[type=radio]:checked {
  width: 100%;
  height: 100%;
  accent-color: #C55556;
  height: 14px;
}

/* ALERTS */
.alert {
  width: 100%;
  border-radius: 0.9rem;
  padding: 2rem;
  font-size: 1.6rem;
}

.alert-danger {
  background-color: rgba(255, 196, 196, 0.6431372549);
  outline: 1px solid #C55556 !important;
  color: #252525;
}

.alert-success {
  background-color: rgba(201, 255, 196, 0.6431372549);
  outline: 1px solid rgb(70, 165, 98) !important;
  color: #252525;
}

/* OTROS */
.cartel-importante {
  display: flex;
  align-items: center;
}
.cartel-importante svg {
  margin-right: 3px;
}

@media (min-width: 800px) {
  .container {
    width: 80%;
  }
  .general-container {
    padding-top: 130px;
  }
}
.general__section {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: max(100vh, 500px);
  z-index: 2;
}

.home__hero {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 40%;
  min-width: max(60vh, 20vw);
  max-width: 80vh;
  z-index: -1;
}

.home__hero-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20%;
  background-color: rgba(255, 255, 255, 0.842);
  padding: 40px;
}
.home__hero-container .home__hero-title {
  font-size: 4rem;
  line-height: 3.2rem;
  font-weight: 800;
  color: #363636;
}
.home__hero-container hr {
  margin: 20px 0;
  width: 80px;
}
.home__hero-container .home__hero-description {
  font-size: 1.3rem;
  color: #7C7C7C;
  margin-bottom: 30px;
}

.homeScreen__slider-circles-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: clamp(10px, 5%, 50px);
}

.slider-button {
  background-color: #CFC6C5;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  transition: 200ms all ease-in-out;
}
.slider-button:not(:last-child) {
  margin-right: 2rem;
}
.slider-button.slider-active {
  background-color: #C55556;
}
.slider-button:hover {
  transform: scale(1.3);
}

@media (min-width: 600px) {
  .home__hero-container {
    width: 80%;
    max-width: 550px;
  }
  .home__hero-container .home__hero-title {
    font-size: 6rem;
    line-height: 5rem;
  }
  .home__hero-container .home__hero-description {
    font-size: 1.5rem;
  }
}
@media (min-width: 1250px) {
  .home__hero-container {
    max-width: 600px;
    width: 60%;
    background-color: transparent;
    padding: 0px;
    margin-bottom: 10%;
  }
  .home__hero-container .home__hero-title {
    font-size: 8rem;
    line-height: 6.8rem;
  }
  .home__hero-container .home__hero-description {
    font-size: 1.6rem;
  }
}
.servicios__section {
  position: relative;
  z-index: 1;
}
.servicios__section .container {
  margin-top: 3rem;
}

.servicios__tables-container {
  position: relative;
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.servicios__tabla tr td {
  color: #363636;
  font-weight: 800;
  padding-right: 20px;
}
.servicios__tabla .main-td {
  color: #7F7F7F;
  flex-grow: 1;
  font-weight: 400;
  width: 90%;
  padding-left: 20px;
  padding-right: 1.2rem;
}

.servicios__title-hero {
  position: absolute;
  font-weight: 900;
  font-size: 12rem;
  width: 100vh;
  top: 0;
  right: 96%;
  color: rgba(141, 141, 141, 0.431372549);
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  z-index: -1;
}

@media (min-width: 900px) {
  .servicios__tables-container {
    display: grid;
    max-width: 1100px;
    margin: 0 auto;
    grid-template-columns: 3fr 2fr;
    gap: 2rem;
  }
}
.loginScreen-container {
  display: flex;
  max-width: 1100px;
  margin-bottom: 10%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.loginScreen__aside {
  width: 40%;
  max-width: 500px;
  background-color: #F5F5F5;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: none;
  margin-right: 2rem;
}

.loginScreen_slider {
  text-align: center;
}
.loginScreen_slider h2 {
  font-size: 4.2rem;
  color: #363636;
}
.loginScreen_slider p {
  width: 90%;
  color: #5c5c5c;
  font-size: 1.6rem;
  margin: 0 auto;
  margin-top: 1rem;
}

.loginScreen_slider_buttons {
  display: flex;
}

.loginScreen-back {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(236, 236, 236);
  position: absolute;
}

.loginScreen__slider-circles-container {
  position: absolute;
  top: calc(100% + 2rem);
}

@media (min-width: 800px) {
  .loginScreen-container {
    justify-self: center;
    align-self: center;
  }
  .loginScreen__aside {
    width: 45%;
    max-width: 500px;
  }
}
@media (min-width: 1190px) {
  .loginScreen-container {
    justify-content: space-between;
    align-items: center;
  }
  .loginScreen__aside {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.reservas__container {
  display: grid;
  max-width: 1200px;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.reservas__calendar-container {
  width: 100% !important;
  min-width: 260px;
  height: -moz-fit-content;
  height: fit-content;
}

.reservas__horarios-container {
  width: 100%;
}

.reservas__horarios-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.reservas__horario-card {
  padding: 10px 10px;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.reservas__horario-card .reservas__horario-index-container {
  font-size: 3rem;
  margin-right: 24px;
  font-weight: 500;
  color: #575757;
  opacity: 0.4;
}
.reservas__horario-card .reservas__horario-turno-container {
  display: flex;
  flex-direction: column;
}
.reservas__horario-card .reservas__horario-turno-container .reservas__horario-turno {
  display: flex;
  flex-direction: column;
}
.reservas__horario-card .reservas__horario-turno-container .reservas__horario-turno h4 {
  font-size: 1.3rem;
  font-weight: 400;
  opacity: 0.7;
}
.reservas__horario-card .reservas__horario-turno-container .reservas__horario-turno p {
  font-size: 2rem;
  font-weight: 600;
}
.reservas__horario-card .reservas__horario-turno-container .reservas__horario-turno:not(:first-child) {
  margin-top: 12px;
}
.reservas__horario-card .reservas__horario-turno-estado {
  position: absolute;
  font-size: 1.4rem;
  padding: 4px 16px;
  letter-spacing: 0.5px;
  border-radius: 12px;
  top: 0;
  left: 1rem;
  transform: translateY(-50%);
}

.reservas__horario-card.horario-disponible {
  background-color: #f3f3f3;
  border: 1px solid #a1a1a1;
}
.reservas__horario-card.horario-disponible .reservas__horario-turno-estado {
  background-color: #56BF45;
  color: rgba(255, 255, 255, 0.863);
}
.reservas__horario-card.horario-disponible:hover {
  border: 1px solid #575757;
  cursor: pointer;
}
.reservas__horario-card.horario-disponible:hover .reservas__horario-index-container {
  opacity: 1;
  transition: opacity 100ms ease-in-out;
}
.reservas__horario-card.horario-disponible.selected {
  border: 1px solid #56BF45;
  background-color: #eaffea;
}
.reservas__horario-card.horario-disponible.selected .reservas__horario-index-container {
  opacity: 1;
}

.reservas__horario-card.horario-ocupado {
  background-color: #E2E2E2;
  border: 1px solid #B9B9B9;
}
.reservas__horario-card.horario-ocupado .reservas__horario-turno-container p,
.reservas__horario-card.horario-ocupado .reservas__horario-turno-container h4 {
  color: #858585;
}
.reservas__horario-card.horario-ocupado .reservas__horario-turno-estado {
  background-color: #C55556;
  color: #E3E3E3;
}

.reservas__text-status {
  font-size: 1.6rem;
}

.reservas__servicios-mods .container {
  max-width: none;
  width: 100%;
}
.reservas__servicios-mods .servicios__tables-container {
  max-width: none;
  width: 100%;
  margin: 0;
}

.reservas__button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.reservas__button-container button:only-child {
  margin-left: auto;
}

.reservas__detalle-turno {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-auto-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 2px;
  margin-top: 2rem;
}

.reservas__importante-turno {
  border-radius: 8px;
  margin: 20px 0 4px 0;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.4705882353);
  display: none;
}

.reservas__importante-turno-min {
  padding: 0rem;
  border-radius: 8px;
  border: none;
  margin: 20px 0 4px 0;
  display: flex;
  flex-wrap: wrap;
}
.reservas__importante-turno-min button {
  width: -moz-max-content;
  width: max-content;
}

.loading-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.432);
  z-index: 10;
  color: white;
  font-size: 1.8rem;
}

.reservas__back div:nth-child(1) {
  width: 30%;
  min-width: 280px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  right: 5%;
  top: -5%;
}
.reservas__back div:nth-child(2) {
  width: 30%;
  min-width: 250px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  left: -5%;
  top: 50vh;
}
.reservas__back div:nth-child(3) {
  width: 20%;
  min-width: 140px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  right: -5%;
  bottom: -5%;
}

@media (min-width: 350px) {
  .reservas__horarios-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 420px) {
  .reservas__horarios-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
  .reservas__horario-card {
    padding: 20px 20px;
    width: 100%;
    margin-top: 1rem;
  }
}
@media (min-width: 500px) {
  .reservas__horarios-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2rem;
    margin-bottom: 2rem;
  }
  .reservas__container .reservas__horarios-container .reservas__horarios-grid .reservas__horario-card .reservas__horario-turno-container .reservas__horario-turno p {
    font-size: 2.5rem;
  }
  .reservas__container .reservas__horarios-container .reservas__horarios-grid .reservas__horario-card .reservas__horario-index-container {
    font-size: 3.5rem;
  }
}
@media (min-width: 600px) {
  .reservas__back div:nth-child(1) {
    min-width: 300px;
    max-width: 450px;
  }
  .reservas__back div:nth-child(2) {
    min-width: 300px;
    max-width: 450px;
  }
  .reservas__back div:nth-child(3) {
    min-width: 300px;
    max-width: 400px;
  }
}
@media (min-width: 800px) {
  .reservas__container {
    grid-template-columns: 2fr 6fr;
  }
  .reservas__horario-card {
    margin-top: 2rem;
  }
}
/* */
.reservas__turno-activo-container {
  padding: 20px;
}

/* ESTILOS FULL CALENDAR */
.fc-event-title {
  display: none;
  color: black !important;
}

.fc-event {
  display: flex;
  align-items: end;
  opacity: 1 !important;
}

.fc .fc-day-past,
.fc .fc-day-today {
  background-color: rgba(122, 122, 122, 0.288) !important;
  color: rgb(104, 104, 104) !important;
}

.fc .fc-day-today {
  background-color: rgba(122, 122, 122, 0.411) !important;
}

.fc-day {
  font-size: 2rem;
}

.fc-day-future:has(.fc-daygrid-bg-harness) {
  cursor: pointer;
}
.fc-day-future:has(.fc-daygrid-bg-harness):hover {
  opacity: 0.8;
}

.fc-day-other {
  cursor: default;
}

.fc-toolbar-title {
  font-size: 2.4rem !important;
  text-transform: uppercase;
  opacity: 1 !important;
}

.fc-scroller {
  height: auto !important;
  overflow: hidden !important;
}

.fc-button-primary {
  background-color: #C55556 !important;
  border-color: #742d2d !important;
  font-size: 1.3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-button-primary:hover {
  opacity: 0.8;
}

@media (min-width: 460px) {
  .fc-event-title {
    display: flex;
  }
}
@media (min-width: 600px) {
  .reservas__importante-turno-min {
    display: none;
  }
  .reservas__importante-turno {
    display: block;
  }
}
.admin-panel {
  padding: 0 20px;
}

.admin-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  flex-grow: 1;
}

.admin-container {
  background-color: rgb(211, 211, 211);
  flex-grow: 1;
  height: 100%;
  padding-bottom: 4rem;
}

.admin__nav-bar {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(214, 214, 214, 0.548);
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.admin__nav-bar .admin__links-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.admin__nav-bar .admin__links-list li a {
  margin: 10px;
}
.admin__nav-bar .admin__links-list p {
  display: none;
}

.admin-panel__title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0 1rem 0;
  padding-right: 45px;
  position: relative;
}
.admin-panel__title-container .section-title {
  margin: 0;
  font-size: 2.5rem;
  line-height: 2.7rem;
}

.admin__nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;
  padding: 8px 20px;
}
.admin__nav-link svg {
  color: rgb(124, 124, 124);
}
.admin__nav-link p {
  color: rgb(124, 124, 124);
  font-size: 1.8rem;
  border-radius: 12px;
  margin-left: 10px;
  padding-top: 4px;
}

.admin__nav-link.admin__nav-link-active svg {
  color: black;
  stroke-width: 2.2;
}
.admin__nav-link.admin__nav-link-active p {
  font-weight: 500;
  color: black;
}

/* USER PANEL */
.admin__panel-container {
  width: 90%;
  margin: 0 auto;
}

.admin__panel-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 1.5rem;
  align-items: start;
  margin-bottom: 1.5rem;
}
.admin__panel-form-grid .formLogin__input-comp {
  margin: 0 !important;
}

.hide-component {
  display: none;
}

.input-with-title > p {
  margin-left: 10px;
  font-size: 1.6rem;
  font-weight: 600;
}

/* Panel de Horarios */
.fc-timegrid-axis {
  background-color: #d9d9d9;
}

.admin-horarios__header {
  display: none;
}

.admin-horarios__header-min {
  display: inline;
}

.fc-col-header-cell {
  font-size: 1.5rem;
}

@media (min-width: 480px) {
  .admin-horarios__header {
    display: inline;
  }
  .admin-horarios__header-min {
    display: none;
  }
}
@media (min-width: 650px) {
  .fc-col-header-cell {
    font-size: 2rem;
  }
}
/* HORARIOS */
.admin__horarios-calendar-container .fc-day-today {
  /* Eliminar background amarillento en el dia actual */
  background-color: transparent;
}
.admin__horarios-calendar-container .fc-timegrid-event-harness-inset .fc-timegrid-event {
  /* Eliminar sombra blanca en los eventos */
  box-shadow: none !important;
}
.admin__horarios-calendar-container .fc-event-main {
  padding: 0 !important;
}
.admin__horarios-calendar-container .evento-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.admin__horarios-calendar-container .evento-container h1 {
  font-size: 1.3rem;
  line-height: 1.1rem;
  color: rgba(255, 255, 255, 0.61);
  text-align: center;
}

.admin__horario_especial-container {
  display: grid;
  grid-template-columns: 1fr 6fr;
}
.admin__horario_especial-container .admin__horario_especial_opciones {
  background-color: rgba(194, 194, 194, 0.877);
  padding: 20px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.admin__horario_especial-container .admin__horario_especial_opciones button:not(:last-child) {
  margin-bottom: 1rem;
}
.admin__horario_especial-container .admin__horario_especial {
  padding: 20px;
  background-color: rgb(163, 163, 163);
}

.admin-panel__horario-subtitle {
  font-size: 2rem;
}

@media (min-width: 690px) {
  .admin-section .admin__nav-bar .admin__links-list p {
    display: block;
  }
}
@media (min-width: 960px) {
  .admin-panel .admin-panel__title-container {
    justify-content: center;
    margin: 3rem 0 3rem 0;
  }
  .admin-panel .admin-panel__title-container .admin-panel__btn-filters {
    display: none;
  }
  .admin-panel .admin-panel__title-container .section-title {
    font-size: 3.5rem;
    line-height: 3.8rem;
  }
  .hide-component {
    display: block !important;
  }
  .admin-section {
    grid-template-columns: 1fr 7fr;
    grid-template-rows: auto;
  }
  .admin-section .admin__nav-bar {
    padding-top: max(20px, 10vh);
  }
  .admin-section .admin__nav-bar .admin__links-list {
    display: block;
  }
  .admin-section .admin__nav-bar .admin__links-list li {
    margin-bottom: 2rem;
  }
  .admin-section .admin__nav-bar .admin__links-list p {
    display: none;
  }
}
@media (min-width: 1050px) {
  .admin-section .admin__nav-bar .admin__links-list p {
    display: block;
  }
}
.historial-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
  height: 100%;
}
.historial-container thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.flex-grow-1 {
  flex-grow: 1;
}

.historial__back div:nth-child(1) {
  width: 30%;
  min-width: 280px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  left: 5%;
  top: -5%;
}
.historial__back div:nth-child(2) {
  width: 30%;
  min-width: 250px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  right: -8%;
  top: 50vh;
}
.historial__back div:nth-child(3) {
  width: 25%;
  min-width: 140px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.3490196078);
  position: absolute;
  left: -10%;
  bottom: -5%;
}

@media (min-width: 600px) {
  .historial__back div:nth-child(1) {
    min-width: 300px;
    max-width: 450px;
  }
  .historial__back div:nth-child(2) {
    min-width: 300px;
    max-width: 450px;
  }
  .historial__back div:nth-child(3) {
    min-width: 300px;
    max-width: 400px;
  }
}
.admin-modal-button {
  cursor: pointer;
}
.admin-modal-button:hover {
  opacity: 0.5;
}

.admin-modal-backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.admin-panel-tablero-container {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.admin-panel-tablero-container div:not(:last-child) {
  margin-right: 2rem;
}

.admin-panel-tablero-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  min-width: 7rem;
}
.admin-panel-tablero-detail-container h3 {
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
}

.admin-panel-tablero-main-detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 75%;
}

.nosotros-section {
  width: 80%;
  height: 100%;
  max-width: 900px;
}
.nosotros-section .nosotros-subtitle {
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3rem;
  color: #363636;
}
.nosotros-section .nosotros-container {
  margin: 2rem 0;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.nosotros-section .nosotros-container .nosotros-contacto-container {
  width: 100%;
  margin-bottom: 3rem;
}
.nosotros-section .nosotros-container .nosotros-contacto-container .nosotros-contacto-data h3 {
  font-size: 1.8rem;
  color: #363636;
}
.nosotros-section .nosotros-container .nosotros-contacto-container .nosotros-contacto-data p {
  margin-top: 4px;
  line-height: 2rem;
  font-size: 1.7rem;
  color: #5c5c5c;
}
.nosotros-section .nosotros-container .nosotros-contacto-container .nosotros-contacto-data:not(:last-child) {
  margin-bottom: 1.8rem;
}
.nosotros-section .nosotros-container .nosotros-mapa {
  max-width: 500px;
  width: 100%;
  min-width: 270px;
  height: 300px;
}
.nosotros-section .nosotros-instagram-container {
  margin: 0 auto;
  margin-top: 3.5rem;
  width: auto;
  flex-grow: 1;
}
.nosotros-section .nosotros-instagram-container h4 {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}
.nosotros-section .nosotros-instagram-container .nosotros-instagram-galery {
  display: flex;
  margin: 0 auto;
  margin-top: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  margin-bottom: 2rem;
}
.nosotros-section .nosotros-instagram-container .nosotros-instagram-galery .nosotros-imagen:not(:last-child) {
  margin-right: 4%;
}
.nosotros-section .nosotros-instagram-container .nosotros-instagram-galery .nosotros-imagen {
  box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  background-color: rgb(27, 27, 27);
  border-radius: 0.8rem;
  aspect-ratio: 1/1;
}
.nosotros-section .nosotros-instagram-container .nosotros-instagram-galery .nosotros-imagen-container {
  width: 21%;
  transition: transform 300ms ease-in-out;
  opacity: 0.8;
  min-width: 120px;
}
.nosotros-section .nosotros-instagram-container .nosotros-instagram-galery .nosotros-imagen-container:hover {
  opacity: 1;
  transition: transform 200ms ease-in-out;
}

.nosotros-imagen-container:nth-child(4) {
  display: none;
}

.nosotros-imagen-container:nth-child(3) {
  display: none;
}

@media (min-width: 600px) {
  .nosotros-section .nosotros-subtitle {
    font-size: 3rem;
  }
  .nosotros-section .nosotros-container {
    flex-direction: row;
  }
  .nosotros-section .nosotros-container .nosotros-contacto-container {
    margin-bottom: 0rem;
    width: -moz-fit-content;
    width: fit-content;
  }
  .nosotros-section .nosotros-container .nosotros-mapa {
    width: 70%;
  }
}
@media (min-width: 490px) {
  .nosotros-imagen-container:nth-child(3) {
    display: block;
  }
}
@media (min-width: 660px) {
  .nosotros-imagen-container:nth-child(4) {
    display: block;
  }
}
.user-screen__form-grid {
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 2rem;
  align-items: end;
  margin-bottom: 1.5rem;
}

.user-screen__button-container {
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
}
.user-screen__button-container button:not(:last-child) {
  margin-right: 1rem;
}

.app-nav-bar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 130px;
  padding: 20px 0;
  z-index: 1000;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.app-nav-bar__logo {
  width: 90px;
  height: 90px;
}

.app-nav-bar__logo__links-list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
  width: 25%;
  max-width: 340px;
  min-width: -moz-min-content;
  min-width: min-content;
  display: none;
}
.app-nav-bar__logo__links-list li {
  margin: 8px;
  list-style: none;
}
.app-nav-bar__logo__links-list li:hover {
  opacity: 0.7;
}

.app-nav-bar__link {
  position: relative;
  text-decoration: none;
  font-weight: 500;
}
.app-nav-bar__link p {
  color: #363636;
  font-size: 1.8rem;
  transition: 300ms all ease-in;
}
.app-nav-bar__link::before {
  content: "";
  background-color: #C55556;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%) translateY(10px);
  opacity: 0;
  transition: 400ms all ease-in-out;
}

.app-nav-bar__link.app-nav-bar__link-active p {
  transform: translateY(-8px);
  transition: 200ms all ease-in !important;
}
.app-nav-bar__link.app-nav-bar__link-active::before {
  content: "";
  background-color: #C55556;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  bottom: -2px;
  transition: 200ms all ease-in-out;
}

.app-nav-bar__buttons-list {
  display: flex;
}
.app-nav-bar__buttons-list li {
  list-style: none;
  margin: 0 4px;
}

.btn-icon.navBar-button {
  background-color: #F5F5F5;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}
.btn-icon.navBar-button p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #363636;
}

.btn-icon.navBar-button:hover {
  opacity: 0.7;
}

nav:has(#nav__servicios.nav__link-active) button {
  background-color: #E6E6E6;
}

.app-menu-bar {
  display: flex;
  height: -moz-min-content;
  height: min-content;
  align-items: center;
}

@media (min-width: 800px) {
  .app-nav-bar {
    padding: 20px;
  }
  .app-nav-bar__logo {
    width: 120px;
    height: 120px;
  }
  .app-nav-bar__logo__links-list {
    display: flex;
  }
  .app-menu-bar {
    display: none;
  }
}
.btn-primary {
  background-color: #C55556;
  font-size: 1.6rem;
  padding: 1.1rem 2.2rem;
  border-radius: 0.8rem;
  color: #f3f3f3;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.btn-secondary {
  background-color: #e0e0e0;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.1rem 2.2rem;
  border-radius: 0.8rem;
  color: #202020;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.btn-primary:hover {
  opacity: 0.8;
  transition: 200ms opacity ease-in-out;
}

.btn-secondary:hover {
  opacity: 0.7;
  transition: 200ms opacity ease-in-out;
}

.btn-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 2rem;
}
.btn-with-icon svg {
  margin-right: 0.6rem;
}
.btn-with-icon:has(.loader) {
  padding: 1.2rem 2.4rem;
}
.btn-with-icon:has(.loader) .loader {
  margin-right: 4px;
}

.btn-icon {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-icon.btn-icon-row p {
  display: none;
}

@media (min-width: 960px) {
  .btn-icon.btn-icon-row.navBar-button {
    flex-direction: row !important;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 20px;
    padding: 0 10px;
  }
  .btn-icon.btn-icon-row.navBar-button p {
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
  }
  .btn-icon.btn-icon-row.navBar-button svg {
    margin-right: 4px;
  }
}
/* BTN TAMAÑOS */
.btn-small {
  padding: 0.8rem 2rem;
  font-size: 1.5rem;
}

/*  */
.btn-loading .loader {
  top: 2px;
  margin-right: 4px;
}

/* BOTONES DEL TIPO LINK */
.btn-anchor {
  font-size: 1.6rem;
  font-weight: 600;
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.btn-anchor-primary {
  color: #C55556;
}

.btn-anchor-secondary {
  color: #7F7F7F;
}

.btn-anchor-white {
  color: #E6E6E6;
}

.btn-anchor-sm {
  font-size: 1.4rem;
  font-weight: 400;
}

.btn-anchor-primary:hover,
.btn-anchor-secondary:hover,
.btn-anchor-white:hover {
  -webkit-text-decoration: underline 1px;
          text-decoration: underline 1px;
  opacity: 70%;
}

/*  */
button:disabled {
  opacity: 0.7 !important;
  cursor: default;
  filter: brightness(110%);
}

.svg-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}

@keyframes transformNormalRightCirclesToBigCircles {
  100% {
    transform: translateX(-50%) translateY(-50%) scale(4);
  }
}
@keyframes transformNormalRightCirclesToBigCirclesDelay {
  100% {
    transform: translateX(-50%) translateY(-50%) scale(4);
  }
}
/*  */
@keyframes transformNormalLeftCirclesToBigCirclesDelay {
  0% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
  99% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) scale(4);
  }
}
@keyframes transformNormalLeftCirclesToBigCircles {
  0% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) scale(4);
  }
}
@keyframes transformBigCirclesToNormalRightCircles {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(4);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}
@keyframes transformNormalLeftCirclesToNormalRightCircles {
  0% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}
@keyframes transformNormalRightCirclesToNormalLeftCircles {
  0% {
    transform: translateY(-50%) scale(1);
  }
  100% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
}
@keyframes transformBigCirclesToNormalLeftCircles {
  0% {
    transform: translateY(-50%) translateX(-50%) scale(4);
  }
  100% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }
}
.background-circles-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

.background-circles {
  position: relative;
  top: 50%;
  right: -50%;
  transform: translateY(-50%);
  z-index: 0;
  width: 70vw;
  min-width: 120vh;
  border-radius: 50%;
  background-color: #F5E4E2;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-circles::before {
  content: "";
  position: absolute;
  width: 43%;
  aspect-ratio: 1/1;
  z-index: 2;
  border-radius: 50%;
  background-color: #F5F5F5;
}
.background-circles::after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #F5E4E2;
}

.transformBigCirclesToNormalRightCircles .background-circles {
  animation-name: transformBigCirclesToNormalRightCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalRightCirclesToBigCircles .background-circles {
  animation-name: transformNormalRightCirclesToBigCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalRightCirclesToNormalLeftCircles .background-circles {
  animation-name: transformNormalRightCirclesToNormalLeftCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalLeftCirclesToNormalRightCircles .background-circles {
  animation-name: transformNormalLeftCirclesToNormalRightCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalLeftCirclesToBigCircles .background-circles {
  animation-name: transformNormalLeftCirclesToBigCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformBigCirclesToNormalLeftCircles .background-circles {
  animation-name: transformBigCirclesToNormalLeftCircles;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalRightCirclesToBigCirclesDelay .background-circles {
  animation-delay: 1000ms;
  animation-name: transformNormalRightCirclesToBigCirclesDelay;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.transformNormalLeftCirclesToBigCirclesDelay .background-circles {
  animation-name: transformNormalLeftCirclesToBigCirclesDelay;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.delay-0 .background-circles {
  animation-delay: 0 !important;
}

.delay-1000 .background-circles {
  animation-delay: 800ms !important;
}

.table__container {
  background-color: #FDFDFD;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.3);
}

.table__hero {
  display: flex;
  align-items: center;
  background-color: #C55556;
  width: 100%;
  color: #FFFFFF;
  padding: 1.6rem 2rem;
  font-size: 1.3rem;
  border-radius: 8px;
  justify-content: space-between;
}

.table__title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__title {
  font-weight: 400;
}

.table__icon-container {
  width: 30px;
  height: 30px;
  background-color: rgb(247, 247, 247);
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---------------------------- */
.table {
  width: 100%;
  border-spacing: 0;
  background-color: #fdfdfd;
}

.table thead th {
  border-bottom: 1px solid rgb(197, 197, 197);
}

.table thead th,
.table tbody td {
  padding: 1.8rem 1.2rem;
  vertical-align: top;
}

.table tbody tr:hover td {
  opacity: 1;
  background-color: rgb(241, 241, 241);
}

.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.table th {
  font-weight: 600;
  font-size: 1.5rem;
  color: #1f1f1f;
}

.table td {
  font-weight: 400 !important;
  font-size: 1.5rem;
  opacity: 0.8;
  border-top: 1px solid #e5e5e5;
}

.table tr:first-child td {
  border-top: none;
}

.table thead {
  background-color: #F2F2F2;
}

.table thead th {
  vertical-align: bottom;
  text-align: left;
}

.table-hover:hover > td,
.table-hover:hover > th {
  background-color: #ff4800;
}

.tabla-td-width-fit {
  width: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.tabla-td-width-fit input {
  max-width: 20px !important;
}

.table-row-actions {
  width: 20px;
}

.table-row-actions-usuarios,
.table-row-actions-servicios {
  display: flex;
  justify-content: end;
}
.table-row-actions-usuarios button,
.table-row-actions-servicios button {
  margin-left: 10px;
}

td:has(.table-row-actions-servicios) {
  width: 100px !important;
}

.table-min thead {
  display: none !important;
}
.table-min .tbody-desk {
  display: none !important;
}
.table-min .tbody-min {
  display: table-header-group !important;
}

td:has(.table-row-actions-select-servicios-table) {
  width: 10px !important;
}

.table-estado-column {
  width: 40px;
}

@media (min-width: 900px) {
  .table-min thead {
    display: table-header-group !important;
  }
  .table-min .tbody-desk {
    display: table-header-group !important;
  }
  .table-min .tbody-min {
    display: none !important;
  }
}
@media (min-width: 600px) {
  .table thead th,
  .table tbody td {
    padding: 2.5rem 1.2rem;
  }
}
/* ADMIN TABLE */
.admin-table thead th {
  padding: 1.5rem 1rem;
  vertical-align: top;
}
.admin-table thead th:first-child {
  padding-left: 2%;
}
.admin-table tbody td {
  padding: 1rem 1rem;
  vertical-align: top;
}
.admin-table tbody td:first-child {
  padding-left: 2%;
}
.admin-table td:has(div.table-row-actions) {
  width: 20px !important;
}
.admin-table td:has(div.table-row-actions) div {
  width: 20px;
  margin-right: 1vw;
}

.formLogin-container {
  width: -moz-max-content;
  width: max-content;
  min-width: 270px;
  max-width: 460px;
}

.formLogin__title {
  font-size: 4rem;
  font-weight: 800;
  color: #363636;
  margin-bottom: 10px;
}

.formLogin__form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.formLogin__input-container {
  position: relative;
}

.formLogin__input {
  height: -moz-min-content;
  height: min-content;
}

.formLogin__form.formLogin__input:last-of-type {
  margin-bottom: 10px;
}

.formLogin__input:focus {
  outline: 1px solid rgba(0, 0, 0, 0.178);
}

.formLogin__buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.formLogin__buttons-container button:not(:first-child) {
  margin-left: 0;
}

.formLogin__input-comp {
  margin-bottom: 20px;
}

.formLogin__input-comp.input-error {
  margin-bottom: 0px;
}

/* CREAR CUENTA */
.formCreate__nombre-apellido-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

@media (min-width: 440px) {
  .formCreate__nombre-apellido-container {
    grid-template-columns: 1fr 1fr;
    gap: 1.4rem;
  }
  .formLogin__buttons-container {
    justify-content: end;
  }
  .formLogin__buttons-container button:not(:first-child) {
    margin-left: clamp(5px, 40%, 40px);
  }
}
@media (min-width: 500px) {
  .formLogin__title {
    line-height: 4.7rem;
    font-size: 5rem;
  }
}
@media (min-width: 800px) {
  .formLogin__title {
    line-height: 6.2rem;
    font-size: 6.5rem;
  }
}
@keyframes identifier {
  100% {
    transform: scale(1.3);
  }
}
.appMenu-aside {
  z-index: 99999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}
.appMenu-aside.appMenu-show .appMenu-container {
  transform: translateX(0);
  transition: 400ms transform ease-in-out;
}
.appMenu-aside.appMenu-show .appMenu-backdrop {
  opacity: 1;
  pointer-events: all;
  transition: opacity 300ms ease-in-out;
}

.appMenu-backdrop {
  pointer-events: all;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.301);
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
}

.appMenu-container {
  pointer-events: all;
  position: fixed;
  background-color: rgba(230, 85, 88, 0.87);
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  min-height: -moz-fit-content;
  min-height: fit-content;
  min-width: 280px;
  width: 100vw;
  max-width: 400px;
  z-index: 9000;
  transform: translateX(100%);
  transition: 400ms transform ease-in-out;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.appMenu-container p,
.appMenu-container svg {
  color: white;
}

.appMenu-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.appMenu-top svg {
  margin-bottom: 2rem;
}

.appMenu-title {
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.appMenu-title span {
  margin-right: 6px;
}
.appMenu-title b {
  font-weight: 900;
}

.appMenu-indicator {
  display: block;
  margin-right: 12px;
  color: #363636;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
}
.appMenu-indicator::first-letter {
  text-transform: capitalize !important;
}
.appMenu-indicator:before {
  content: "";
  background-color: #C55556;
  width: 10px;
  top: 3px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: -14px;
}

.appMenu-list li {
  display: flex;
  align-items: center;
  width: -moz-min-content;
  width: min-content;
  font-size: 2rem;
  list-style: none;
  margin-bottom: 2.6rem;
  cursor: pointer;
}
.appMenu-list li svg {
  margin-right: 4px;
}
.appMenu-list li p {
  width: -moz-max-content;
  width: max-content;
}
.appMenu-list li:hover {
  opacity: 0.7;
}
.appMenu-list li a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.appMenu-navigation-list li {
  display: flex;
  width: -moz-min-content;
  width: min-content;
  display: flex;
  align-items: center;
  font-size: 2rem;
  list-style: none;
  cursor: pointer;
}
.appMenu-navigation-list li svg {
  margin-right: 4px;
}
.appMenu-navigation-list li p {
  width: -moz-max-content;
  width: max-content;
}
.appMenu-navigation-list li:hover {
  opacity: 0.7;
}

.misDatosSection .misDatos-title {
  display: flex;
  align-items: center;
  font-size: 2.3rem;
  width: -moz-max-content;
  width: max-content;
  font-weight: 400;
  animation-name: identifier;
}
.misDatosSection .misDatos-title svg {
  margin-right: 4px;
}
.misDatosSection hr {
  margin: 20px 0 40px 0;
  opacity: 0.5;
}
.misDatosSection .misDatos-info {
  margin-top: 10px;
}
.misDatosSection .misDatos-info p {
  margin: 20px 0;
  font-size: 1.6rem;
}

.misDatos-info-container {
  display: flex;
  align-items: center;
}
.misDatos-info-container div {
  margin: 0 0 0 4px;
}

.misDatos-btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.misNotificaciones-info {
  font-size: 1.7rem;
  font-weight: 400;
}

.user-menu-button {
  display: none !important;
}

.appMenu-list.nav-menu {
  display: block;
}

@media (min-width: 800px) {
  .appMenu-no-login {
    display: none;
  }
  .appMenu-list.nav-menu {
    display: none;
  }
  .user-menu-button {
    display: flex !important;
  }
  .appMenu-container {
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 30%;
    max-width: 430px;
  }
}
@media (min-height: 470px) and (min-width: 800px) {
  .appMenu-top {
    display: block;
  }
  .appMenu-title {
    font-size: 3.4rem;
    line-height: 3.5rem;
    margin: 8% 0 22% 0;
  }
}
.loader {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progress__component-container {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}

.progress__number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDEFF0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 1.7rem;
  font-weight: 600;
}

.progress__item-container:not(:first-child) .progress__number-container::before {
  content: "";
  height: 4px;
  position: absolute;
  right: 100%;
  width: 25vw;
  background-color: #bdbdbd;
  z-index: -1;
}

.progress__item-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  flex-direction: column;
}
.progress__item-container p {
  font-weight: 400;
  color: #b3b4b4;
}
.progress__item-container .progress__number-container {
  color: #a1a1a1;
}
.progress__item-container.active p {
  color: #252525;
  font-weight: 600;
}
.progress__item-container.active .progress__number-container {
  font-weight: 600;
  color: #252525;
}
.progress__item-container.active .progress__number-container::before {
  background-color: #636363;
}
.progress__item-container.completed .progress__number-container {
  color: #626364;
  background-color: #C0E9C2;
}
.progress__item-container.completed .progress__number-container::before {
  background-color: #636363;
}
.progress__item-container.completed p {
  color: #626364;
  font-weight: 500;
}

.progress-description {
  font-size: 1.7rem;
  font-weight: 500;
  margin-left: 12px;
  display: none;
  position: absolute;
}

.progress__item-container.active .progress-description {
  display: flex;
  top: 100%;
  width: -moz-max-content;
  width: max-content;
}

.progress__item-container.active:first-child .progress-description {
  left: -1rem;
}

.progress__item-container.completed:last-child .progress-description {
  display: flex;
  top: 100%;
  right: 0;
}

@media (min-width: 800px) {
  .progress__item-container {
    flex-direction: row;
  }
  .progress__item-container:not(:first-child) .progress__number-container::before {
    width: 4px;
    height: 30px;
    top: -20px;
    right: auto;
  }
  .progress-description {
    display: block;
    position: static;
  }
  .progress__component-container {
    width: -moz-fit-content;
    width: fit-content;
    display: block;
  }
}
.modal-confirmation-back {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1099;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirmation-title {
  margin-bottom: 15px;
}

.modal-confirmation-container {
  position: relative;
  border-radius: 8px;
  padding: 30px 50px 20px 50px;
  width: 20%;
  min-width: 200px;
  max-width: 500px;
  margin-bottom: 4rem;
  background-color: rgb(247, 247, 247);
}

.modal-confirmation__btn-container {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.modal-confirmation__btn-container button {
  padding: 6px 16px;
}

#modal-confirmation-cruz {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
}

.tooltip {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 1.6rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip:hover .tooltip-detail {
  opacity: 1;
  transform: translateX(-50%) translateY(-5px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.tooltip-detail {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 10px;
  background-color: rgb(56, 56, 56);
  color: rgba(238, 238, 238, 0.911);
  width: -moz-max-content;
  width: max-content;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-detail::after {
  position: absolute;
  content: "";
  top: 100%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(56, 56, 56);
}

.badge {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 1.6rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge:hover .badge-detail {
  opacity: 1;
  transform: translateX(calc(25% + 10px)) translateY(-5px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.badge-detail {
  opacity: 0;
  position: absolute;
  right: 100%;
  transform: translateX(calc(25% + 10px));
  bottom: 100%;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 10px;
  background-color: rgb(56, 56, 56);
  color: rgba(238, 238, 238, 0.911);
  width: -moz-max-content;
  width: max-content;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge-detail::after {
  position: absolute;
  content: "";
  top: 100%;
  width: 0;
  height: 0;
  right: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(56, 56, 56);
}

.badge.center .badge-detail {
  right: auto;
  transform: none;
}
.badge.center .badge-detail::after {
  right: auto;
}
.badge.center:hover .badge-detail {
  transform: translateY(-5px);
}

.select {
  position: relative;
}

.select::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  height: 7px;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #555 transparent transparent transparent;
  transform: translateY(-25%);
  pointer-events: none;
}

select {
  display: grid;
  width: 100%;
  padding: 12px;
  padding-left: 20px;
  border-radius: 20px;
  color: #363636;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.6rem;
}

select:focus {
  outline: 1px solid rgba(0, 0, 0, 0.178);
}

.pagination-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 0%));
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.pagination-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  cursor: pointer;
}
.pagination-button:hover {
  opacity: 0.5;
}
.pagination-button.active {
  background-color: #C55556;
  color: #f3f3f3;
  border: none;
  font-weight: 500;
}

.modal__container {
  background-color: white;
  border-radius: 8px;
  padding: 0 0 0.5rem 0;
  max-height: 90vh;
  overflow-y: auto;
  pointer-events: all;
}

.modal__title-container {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem 1rem 2.5rem;
  margin-bottom: 1rem;
}
.modal__title-container h1 {
  font-size: 2.4rem;
  margin-right: 3rem;
}

.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.37);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 0 2.5rem 2rem 2.5rem;
}

.modal-footer {
  padding: 0 2.5rem;
  padding-bottom: 1.5rem;
}

.modal__btn-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.alert-container {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: -15px;
  border-radius: 4px;
  font-size: 1.5rem;
}

.alert-warning {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.alert-success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.alert-info {
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  color: #004085;
}

/* ALERT NOTIFICATION - PORTAL */
.alert-notification-warning {
  background-color: rgba(248, 215, 218, 0.9254901961);
  border: 1px solid rgba(238, 94, 108, 0.7333333333);
  color: #721c24;
}

.alert-notification-success {
  background-color: rgba(212, 237, 218, 0.9254901961);
  border: 1px solid #5bd477;
  color: #155724;
}

.alert-notification-info {
  background-color: rgba(204, 229, 255, 0.9333333333);
  border: 1px solid #6db1fa;
  color: #004085;
}

@keyframes contarTiempoAlerta {
  0% {
    transform: translateX(-100%);
  }
  13% {
    transform: translateX(-100%);
  }
  87% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes move-in-and-wait {
  0% {
    transform: translateX(200%);
  }
  13% {
    transform: translateX(0%);
  }
  87% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(200%);
  }
}
@keyframes move-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(200%);
  }
}
.alert-notification-wrapper {
  width: 310px;
  height: 100px;
  position: fixed;
  right: 3rem;
  top: 15rem;
  z-index: 9999;
  transform: translateX(200%);
  pointer-events: none;
}
.alert-notification-wrapper.show {
  animation-name: move-in-and-wait;
  animation-duration: 8000ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.alert-notification-wrapper.show .alert-notification::before {
  animation-name: contarTiempoAlerta;
  animation-duration: 8000ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.alert-notification-wrapper:has(.alert-notification:hover) {
  animation-play-state: paused;
}
.alert-notification-wrapper .alert-notification {
  width: 310px;
  height: 100px;
  padding: 20px 33px 20px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  pointer-events: all;
}
.alert-notification-wrapper .alert-notification svg {
  margin-right: 20x;
}
.alert-notification-wrapper .alert-notification::before {
  content: "";
  height: 5px;
  width: 100%;
  background-color: rgba(124, 124, 124, 0.781);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  transform: translateX(-100%);
}
.alert-notification-wrapper .alert-notification:hover::before {
  animation-play-state: paused;
}
.alert-notification-wrapper .alert-notification.hide {
  animation-name: move-out;
  animation-duration: 600ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.alert-notification-wrapper .alert-notification.hide::before {
  animation-play-state: paused;
}

.folder-container {
  display: flex;
  flex-direction: column;
}
.folder-container .folder-buttons-top {
  display: flex;
  justify-content: space-between;
}
.folder-container .folder-right-buttons-container {
  display: flex;
  margin-bottom: 4px;
}
.folder-container .folder-right-buttons-container button:not(:last-child) {
  margin-right: 1rem;
}
.folder-container .folder-buttons-container {
  display: flex;
}
.folder-container .folder-buttons-container button {
  border: none;
  cursor: pointer;
  background-color: rgb(228, 228, 228);
}
.folder-container .folder-buttons-container button p {
  color: rgb(136, 136, 136);
  font-size: 1.8rem;
  border-radius: 12px;
  margin: 0;
  padding: 12px 26px;
}
.folder-container .folder-buttons-container button:hover {
  background-color: rgb(224, 224, 224);
}
.folder-container .folder-buttons-container button:hover p {
  color: rgb(34, 34, 34);
}
.folder-container .folder-buttons-container button.active {
  background-color: rgb(242, 242, 242);
  cursor: default;
}
.folder-container .folder-buttons-container button.active p {
  color: black;
  font-weight: 500;
}

.fab-wpp__container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: none;
}

.fab-wpp {
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: transparent;
  cursor: pointer;
  pointer-events: all;
}

.fab-wpp__bg {
  position: relative;
  z-index: 9;
  background-color: #2db742;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  transition: transform 400ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fab-wpp__bg .wpp-icon {
  background-image: url("../assets/img/wpp-icon.png");
  -o-object-fit: contain;
     object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 2px;
  width: 100%;
  height: 100%;
}
.fab-wpp__bg .wpp-icon path {
  fill: #f3f3f3;
}
.fab-wpp__bg:hover {
  transform: scale(1.2);
  transition: transform 300ms ease-in-out;
}

.fab-wpp__container:has(.hover-effect:hover) .fab-wpp__message {
  opacity: 1;
  transform: translateX(7px);
  transition: all 300ms ease-in-out;
}

.fab-wpp__message {
  color: white;
  height: -moz-fit-content;
  height: fit-content;
  left: 0rem;
  width: -moz-max-content;
  width: max-content;
  background-color: rgb(32, 116, 57);
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  z-index: -9;
  opacity: 0;
  font-size: 1.5rem;
  transform: translateX(40px);
  transition: all 400ms ease-in-out;
}

.wpp__container {
  width: auto;
  height: 320px;
  position: fixed;
  bottom: 0;
  right: 2rem;
  left: 2rem;
  transform: translateY(100%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: transform 400ms ease-in-out;
}
.wpp__container.show-chat {
  transition: transform 400ms ease-in-out;
  transform: translateY(0);
}
@media (min-width: 400px) {
  .wpp__container {
    left: auto;
    width: 320px;
    right: 2rem;
  }
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scaleX(1);
  }
}
.rubberBand {
  animation-delay: 4s;
  animation-name: rubberBand;
  animation-duration: 1s;
  animation-fill-mode: both;
}/*# sourceMappingURL=styles.css.map */