@keyframes transformNormalRightCirclesToBigCircles {
    100% {
        transform: translateX(-50%) translateY(-50%) scale(4);
    }
}

@keyframes transformNormalRightCirclesToBigCirclesDelay {
    100% {
        transform: translateX(-50%) translateY(-50%) scale(4);
    }
}

/*  */

@keyframes transformNormalLeftCirclesToBigCirclesDelay {
    0% {
        transform: translateY(-50%) translateX(-100%) scale(1);
    }

    99% {
        transform: translateY(-50%) translateX(-100%) scale(1);
    }

    100% {
        transform: translateY(-50%) translateX(-50%) scale(4);
    }
}

@keyframes transformNormalLeftCirclesToBigCircles {
    0% {
        transform: translateY(-50%) translateX(-100%) scale(1);
    }

    100% {
        transform: translateY(-50%) translateX(-50%) scale(4);
    }
}

@keyframes transformBigCirclesToNormalRightCircles {
    0% {
        transform: translateX(-50%) translateY(-50%) scale(4);

    }

    100% {
        transform: translateY(-50%) scale(1);
    }
}

@keyframes transformNormalLeftCirclesToNormalRightCircles {
    0% {
        transform: translateY(-50%) translateX(-100%) scale(1);

    }

    100% {
        transform: translateY(-50%) scale(1);
    }
}

@keyframes transformNormalRightCirclesToNormalLeftCircles {
    0% {
        transform: translateY(-50%) scale(1);
    }

    100% {
        transform: translateY(-50%) translateX(-100%) scale(1);
    }
}

@keyframes transformBigCirclesToNormalLeftCircles {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(4);
    }

    100% {
        transform: translateY(-50%) translateX(-100%) scale(1);
    }
}



.background-circles-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
}

.background-circles {
    position: relative;
    top: 50%;
    right: -50%;
    transform: translateY(-50%);
    z-index: 0;
    width: 70vw;
    min-width: 120vh;
    border-radius: 50%;
    background-color: $pink-500;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        width: 43%;
        aspect-ratio: 1/1;
        z-index: 2;
        border-radius: 50%;
        background-color: $pink-100;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 90%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $pink-500;
    }
}

.transformBigCirclesToNormalRightCircles {
    .background-circles {
        animation-name: transformBigCirclesToNormalRightCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformNormalRightCirclesToBigCircles {
    .background-circles {
        animation-name: transformNormalRightCirclesToBigCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformNormalRightCirclesToNormalLeftCircles {
    .background-circles {
        animation-name: transformNormalRightCirclesToNormalLeftCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformNormalLeftCirclesToNormalRightCircles {
    .background-circles {
        animation-name: transformNormalLeftCirclesToNormalRightCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformNormalLeftCirclesToBigCircles {
    .background-circles {
        animation-name: transformNormalLeftCirclesToBigCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformBigCirclesToNormalLeftCircles {
    .background-circles {
        animation-name: transformBigCirclesToNormalLeftCircles;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}


.transformNormalRightCirclesToBigCirclesDelay {
    .background-circles {
        animation-delay: 1000ms;
        animation-name: transformNormalRightCirclesToBigCirclesDelay;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.transformNormalLeftCirclesToBigCirclesDelay {
    .background-circles {
        animation-name: transformNormalLeftCirclesToBigCirclesDelay;
        animation-duration: 400ms;
        animation-fill-mode: forwards;
    }
}

.delay-0 {
    .background-circles {
        animation-delay: 0 !important;
    }
}

.delay-1000 {
    .background-circles {
        animation-delay: 800ms !important;
    }
}