@keyframes identifier {
    100% {
        transform: scale(1.3)
    }
}

.appMenu-aside {
    z-index: 99999;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;

    &.appMenu-show .appMenu-container {
        transform: translateX(0);
        transition: 400ms transform ease-in-out;
    }

    &.appMenu-show .appMenu-backdrop {
        opacity: 1;
        pointer-events: all;
        transition: opacity 300ms ease-in-out
    }
}

.appMenu-backdrop {
    pointer-events: all;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.301);
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms ease-in-out
}

.appMenu-container {
    pointer-events: all;
    position: fixed;
    background-color: rgba(230, 85, 88, 0.87);
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    min-height: fit-content;
    min-width: 280px;
    width: 100vw;
    max-width: 400px;
    z-index: 9000;
    transform: translateX(100%);
    transition: 400ms transform ease-in-out;
    padding: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p,
    svg {
        color: white;
    }
}

.appMenu-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    svg {
        margin-bottom: 2rem;
    }
}

.appMenu-title {
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    span {
        margin-right: 6px;
    }

    b {
        font-weight: 900;
    }

}

.appMenu-indicator {
    display: block;
    margin-right: 12px;
    color: #363636;
    font-size: 1.8rem;
    font-weight: 500;
    position: relative;

    &::first-letter {
        text-transform: capitalize !important;
    }

    &:before {
        content: "";
        background-color: #C55556;
        width: 10px;
        top: 3px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: -14px;
    }
}

.appMenu-list {
    li {
        display: flex;
        align-items: center;
        width: min-content;
        font-size: 2rem;
        list-style: none;
        margin-bottom: 2.6rem;
        cursor: pointer;

        svg {
            margin-right: 4px;
        }

        p {
            width: max-content;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    li a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }

}

.appMenu-navigation-list {
    li {
        display: flex;
        width: min-content;
        display: flex;
        align-items: center;
        font-size: 2rem;
        list-style: none;

        cursor: pointer;

        svg {
            margin-right: 4px;
        }

        p {
            width: max-content;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}

.misDatosSection {
    .misDatos-title {
        display: flex;
        align-items: center;
        font-size: 2.3rem;
        width: max-content;
        font-weight: 400;
        animation-name: identifier;

        svg {
            margin-right: 4px;
        }
    }

    hr {
        margin: 20px 0 40px 0;
        opacity: 0.5;
    }

    .misDatos-info {
        margin-top: 10px;

        p {
            margin: 20px 0;
            font-size: 1.6rem;
        }
    }

}

.misDatos-info-container {
    display: flex;
    align-items: center;

    div {
        margin: 0 0 0 4px;
    }
}

.misDatos-btns-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}


.misNotificaciones-info {
    font-size: 1.7rem;
    font-weight: 400;
}

.user-menu-button {
    display: none !important;
}

.appMenu-list.nav-menu {
    display: block;
}

@media (min-width:800px) {
    .appMenu-no-login {
        display: none;
    }

    .appMenu-list.nav-menu {
        display: none;
    }

    .user-menu-button {
        display: flex !important;
    }

    .appMenu-container {
        min-height: fit-content;
        width: 30%;
        max-width: 430px;
    }
}

@media (min-height:470px) and (min-width:800px) {
    .appMenu-top {
        display: block;
    }

    .appMenu-title {
        font-size: 3.4rem;
        line-height: 3.5rem;
        margin: 8% 0 22% 0;
    }
}