.progress__component-container {
    position: relative;
    height: fit-content;
    // width: fit-content;
    display: flex;
    justify-content: space-between;
}

.progress__number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDEFF0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    font-size: 1.7rem;
    font-weight: 600;
}

.progress__item-container:not(:first-child) .progress__number-container::before {
    content: "";
    height: 4px;
    position: absolute;
    right: 100%;
    width: 25vw;
    background-color: #bdbdbd;
    z-index: -1;
}

.progress__item-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    flex-direction: column;

    p {
        font-weight: 400;
        color: #b3b4b4;
    }

    .progress__number-container {
        color: #a1a1a1;
    }


    &.active {
        p {
            color: #252525;
            font-weight: 600;
        }

        .progress__number-container {
            font-weight: 600;
            color: #252525;

            &::before {
                background-color: #636363;
            }
        }
    }

    &.completed {
        .progress__number-container {
            color: #626364;
            background-color: #C0E9C2;

            &::before {
                background-color: #636363;
            }
        }

        p {
            color: #626364;
            font-weight: 500;
        }
    }
}

.progress-description {
    font-size: 1.7rem;
    font-weight: 500;
    margin-left: 12px;
    display: none;
    position: absolute;
}

.progress__item-container.active .progress-description {
    display: flex;
    top: 100%;
    width: max-content;
}

.progress__item-container.active:first-child .progress-description {
    left: -1rem;
}

.progress__item-container.completed:last-child .progress-description {
    display: flex;
    top: 100%;
    right: 0;
}

@media (min-width:800px) {
    .progress__item-container {
        flex-direction: row;
    }

    .progress__item-container:not(:first-child) .progress__number-container::before {
        width: 4px;
        height: 30px;
        top: -20px;
        right: auto;
    }

    .progress-description {
        display: block;
        position: static;
    }

    .progress__component-container {
        width: fit-content;
        display: block;
    }
}