.modal-confirmation-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1099;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-confirmation-title {
    margin-bottom: 15px;
}

.modal-confirmation-container {
    position: relative;
    border-radius: 8px;
    padding: 30px 50px 20px 50px;
    width: 20%;
    min-width: 200px;
    max-width: 500px;
    margin-bottom: 4rem;
    background-color: rgb(247, 247, 247);
}

.modal-confirmation__btn-container {
    display: flex;
    justify-content: space-between;
    // background-color: green;
    padding-top: 15px;

    button {
        padding: 6px 16px;
    }
}

#modal-confirmation-cruz {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
}