.folder-container {
    display: flex;
    flex-direction: column;

    .folder-buttons-top {
        display: flex;
        justify-content: space-between;
    }

    .folder-right-buttons-container {
        display: flex;
        margin-bottom: 4px;

        button:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .folder-buttons-container {
        display: flex;

        button {
            border: none;
            cursor: pointer;
            background-color: rgb(228, 228, 228);

            p {
                color: rgb(136, 136, 136);
                font-size: 1.8rem;
                border-radius: 12px;
                margin: 0;
                padding: 12px 26px;
            }

            &:hover {
                background-color: rgb(224, 224, 224);

                p {
                    color: rgb(34, 34, 34);
                }
            }

            &.active {
                background-color: rgb(242 242 242);
                cursor: default;

                p {
                    color: black;
                    font-weight: 500;
                }
            }
        }
    }
}