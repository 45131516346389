.reservas__container {
    display: grid;
    max-width: 1200px;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    column-gap: 2rem;
}

.reservas__calendar-container {
    width: 100% !important;
    min-width: 260px;
    height: fit-content;
}

.reservas__horarios-container {
    width: 100%;
}

.reservas__horarios-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.reservas__horario-card {
    padding: 10px 10px;
    width: 100%;
    margin-top: 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;

    .reservas__horario-index-container {
        font-size: 3rem;
        margin-right: 24px;
        font-weight: 500;
        color: #575757;
        opacity: 0.4;
    }

    .reservas__horario-turno-container {
        display: flex;
        flex-direction: column;

        .reservas__horario-turno {
            display: flex;
            flex-direction: column;

            h4 {
                font-size: 1.3rem;
                font-weight: 400;
                opacity: 0.7;
            }

            p {
                font-size: 2rem;
                // font-size: 2.5rem;
                font-weight: 600;
            }
        }

        .reservas__horario-turno:not(:first-child) {
            margin-top: 12px;
        }
    }

    .reservas__horario-turno-estado {
        position: absolute;
        font-size: 1.4rem;
        padding: 4px 16px;
        letter-spacing: 0.5px;
        border-radius: 12px;
        top: 0;
        left: 1rem;
        transform: translateY(-50%)
    }
}

.reservas__horario-card.horario-disponible {
    background-color: #f3f3f3;
    border: 1px solid #a1a1a1;

    .reservas__horario-turno-estado {
        background-color: #56BF45;
        color: rgba(255, 255, 255, 0.863);
    }

    &:hover {
        border: 1px solid #575757;
        cursor: pointer;

        .reservas__horario-index-container {
            opacity: 1;
            transition: opacity 100ms ease-in-out;
        }

    }

    &.selected {
        border: 1px solid #56BF45;
        background-color: #eaffea;

        .reservas__horario-index-container {
            opacity: 1;
        }
    }
}

.reservas__horario-card.horario-ocupado {
    background-color: #E2E2E2;
    border: 1px solid #B9B9B9;

    .reservas__horario-turno-container p,
    .reservas__horario-turno-container h4 {
        color: #858585;
    }

    .reservas__horario-turno-estado {
        background-color: #C55556;
        color: #E3E3E3;
    }
}


.reservas__text-status {
    font-size: 1.6rem;
}

.reservas__servicios-mods {
    .container {
        max-width: none;
        width: 100%;
    }

    .servicios__tables-container {
        max-width: none;
        width: 100%;
        margin: 0;
    }
}

.reservas__button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    button:only-child {
        margin-left: auto;
    }
}

.reservas__detalle-turno {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-auto-rows: auto;
    column-gap: 10px;
    row-gap: 2px;
    margin-top: 2rem;
}

.reservas__importante-turno {
    border-radius: 8px;
    margin: 20px 0 4px 0;
    padding: 2rem;
    border: 1px solid #00000078;
    display: none;
}

.reservas__importante-turno-min {
    padding: 0rem;
    border-radius: 8px;
    border: none;
    margin: 20px 0 4px 0;
    display: flex;
    flex-wrap: wrap;

    button {
        width: max-content;
    }
}



.loading-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.432);
    z-index: 10;
    color: white;
    font-size: 1.8rem;
}

.reservas__back {
    div:nth-child(1) {
        width: 30%;
        min-width: 280px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        right: 5%;
        top: -5%;
    }

    div:nth-child(2) {
        width: 30%;
        min-width: 250px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        left: -5%;
        top: 50vh;
    }

    div:nth-child(3) {
        width: 20%;
        min-width: 140px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        right: -5%;
        bottom: -5%;
    }


}

@media(min-width:350px) {
    .reservas__horarios-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media(min-width:420px) {
    .reservas__horarios-grid {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

    }

    .reservas__horario-card {
        padding: 20px 20px;
        width: 100%;
        margin-top: 1rem;
    }
}

@media (min-width:500px) {

    .reservas__horarios-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 2rem;
        margin-bottom: 2rem;
    }

    .reservas__container .reservas__horarios-container .reservas__horarios-grid .reservas__horario-card {
        .reservas__horario-turno-container .reservas__horario-turno p {
            font-size: 2.5rem;
        }

        .reservas__horario-index-container {
            font-size: 3.5rem;
        }
    }
}

@media (min-width:600px) {
    .reservas__back {
        div:nth-child(1) {
            min-width: 300px;
            max-width: 450px;
        }

        div:nth-child(2) {
            min-width: 300px;
            max-width: 450px;
        }

        div:nth-child(3) {
            min-width: 300px;
            max-width: 400px;
        }
    }
}

@media (min-width:800px) {
    .reservas__container {
        grid-template-columns: 2fr 6fr;
    }

    .reservas__horario-card {
        margin-top: 2rem;
    }
}

/* */

.reservas__turno-activo-container {
    padding: 20px;
}


/* ESTILOS FULL CALENDAR */
.fc-event-title {
    display: none;
    color: black !important;
}

.fc-event {
    display: flex;
    align-items: end;
    opacity: 1 !important;
}

.fc .fc-day-past,
.fc .fc-day-today {
    background-color: rgba(122, 122, 122, 0.288) !important;
    color: rgb(104, 104, 104) !important;
}

.fc .fc-day-today {
    background-color: rgba(122, 122, 122, 0.411) !important;
}

.fc-day {
    font-size: 2rem;
}

.fc-day-future:has(.fc-daygrid-bg-harness) {
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.fc-day-other {
    cursor: default;
}

.fc-toolbar-title {
    font-size: 2.4rem !important;
    text-transform: uppercase;
    opacity: 1 !important;
}


.fc-scroller {
    height: auto !important;
    overflow: hidden !important;
}

.fc-button-primary {
    background-color: $rojo-400 !important;
    border-color: #742d2d !important;
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc-button-primary:hover {
    opacity: 0.8;
}



@media(min-width:460px) {
    .fc-event-title {
        display: flex;
    }
}

@media(min-width:600px) {
    .reservas__importante-turno-min {
        display: none;
    }

    .reservas__importante-turno {
        display: block;
    }
}