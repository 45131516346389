.tooltip {
    width: fit-content;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 1.6rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover .tooltip-detail {
        opacity: 1;
        transform: translateX(-50%) translateY(-5px);
        transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    }
}
.tooltip-detail {
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    border-radius: 4px;
    font-weight: 500;
    padding: 4px 10px;
    background-color: rgb(56, 56, 56);
    color: rgba(238, 238, 238, 0.911);
    width: max-content;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        position: absolute;
        content: "";
        top: 100%;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgb(56, 56, 56);
    }
}

.badge {
    width: fit-content;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 1.6rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover .badge-detail {
        opacity: 1;
        transform: translateX(calc(25% + 10px)) translateY(-5px);
        transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    }
}

.badge-detail {
    opacity: 0;
    position: absolute;
    right: 100%;
    transform: translateX(calc(25% + 10px));
    bottom: 100%;
    border-radius: 4px;
    font-weight: 500;
    padding: 4px 10px;
    background-color: rgb(56, 56, 56);
    color: rgba(238, 238, 238, 0.911);
    width: max-content;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        position: absolute;
        content: "";
        top: 100%;
        width: 0;
        height: 0;
        right: 10px;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgb(56, 56, 56);
    }
}

.badge.center {
    .badge-detail {
        right: auto;
        transform: none;


        &::after {
            right: auto;
        }
    }

    &:hover .badge-detail {
        transform: translateY(-5px);
    }
}