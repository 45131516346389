.fab-wpp__container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 9999;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    pointer-events: none;
}

.fab-wpp {
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: transparent;
    cursor: pointer;
    pointer-events:all;
}

.fab-wpp__bg {
    position: relative;
    z-index: 9;
    background-color: #2db742;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    box-shadow: 0 6px 8px 2px rgb(0 0 0 / 14%);
    cursor: pointer;
    transition: transform 400ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    .wpp-icon {
        background-image: url("../assets/img/wpp-icon.png");
        object-fit: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 2px;
        width: 100%;
        height: 100%;

        path {
            fill: $white-100;
        }
    }

    &:hover {
        transform: scale(1.2);
        transition: transform 300ms ease-in-out;
    }
}

.fab-wpp__container:has(.hover-effect:hover) .fab-wpp__message {
    opacity: 1;
    transform: translateX(7px);
    transition: all 300ms ease-in-out;
}

.fab-wpp__message {
    color: white;
    height: fit-content;
    left: 0rem;
    width: max-content;
    background-color: rgb(32, 116, 57);
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    z-index: -9;
    opacity: 0;
    font-size: 1.5rem;
    transform: translateX(40px);
    transition: all 400ms ease-in-out;
}

.wpp__container {
    width: auto;
    height: 320px;
    position: fixed;
    bottom: 0;
    right: 2rem;
    left: 2rem;
    transform: translateY(100%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    transition: transform 400ms ease-in-out;

    &.show-chat {
        transition: transform 400ms ease-in-out;
        transform: translateY(0);
    }

    @media (min-width:400px) {
        left: auto;
        width: 320px;
        right: 2rem;
    }
}


@keyframes rubberBand {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}


.rubberBand {
    animation-delay: 4s;

    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;

    -webkit-animation-duration: 1s;
    animation-duration: 1s;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;


}