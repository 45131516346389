.table__container {
    background-color: #FDFDFD;
    border-radius: 8px;
    // overflow: hidden;
    height: fit-content;
    -webkit-box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.3);
}

.table__hero {
    display: flex;
    align-items: center;
    background-color: $rojo-400;
    width: 100%;
    color: $white-0;
    padding: 1.6rem 2rem;
    font-size: 1.3rem;
    border-radius: 8px;
    justify-content: space-between;

}

.table__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table__title {
    font-weight: 400;
}

.table__icon-container {
    width: 30px;
    height: 30px;
    background-color: rgb(247, 247, 247);
    margin-right: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ---------------------------- */

.table {
    width: 100%;
    border-spacing: 0;
    background-color: #fdfdfd;
}

.table thead th {
    border-bottom: 1px solid rgb(197, 197, 197);
}

.table thead th,
.table tbody td {
    padding: 1.8rem 1.2rem;
    vertical-align: top;
}

.table tbody tr:hover td {
    opacity: 1;
    background-color: rgb(241, 241, 241);
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.table th {
    font-weight: 600;
    font-size: 1.5rem;
    color: #1f1f1f;
}

.table td {
    font-weight: 400 !important;
    font-size: 1.5rem;
    opacity: 0.8;
    border-top: 1px solid #e5e5e5;
}


.table tr:first-child td {
    border-top: none;
}

.table thead {
    background-color: #F2F2F2;
}

.table thead th {
    vertical-align: bottom;
    text-align: left;
}

.table-hover:hover>td,
.table-hover:hover>th {
    background-color: #ff4800;
}


.tabla-td-width-fit {
    width: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;

    input {
        max-width: 20px !important;
    }
}


.table-row-actions {
    width: 20px;
}

.table-row-actions-usuarios,
.table-row-actions-servicios {
    display: flex;
    justify-content: end;

    button {
        margin-left: 10px;
    }
}

td:has(.table-row-actions-servicios) {
    width: 100px !important;
}

.table-min {
    thead {
        display: none !important;
    }

    .tbody-desk {
        display: none !important;
    }

    .tbody-min {
        display: table-header-group !important;
    }
}

td:has(.table-row-actions-select-servicios-table) {
    width: 10px !important;
}


.table-estado-column {
    width: 40px;
}

@media (min-width:900px) {
    .table-min {
        thead {
            display: table-header-group !important;
        }

        .tbody-desk {
            display: table-header-group !important;
        }

        .tbody-min {
            display: none !important;
        }
    }
}

@media (min-width:600px) {

    .table thead th,
    .table tbody td {
        padding: 2.5rem 1.2rem;
    }
}


/* ADMIN TABLE */
.admin-table {

    thead th {
        padding: 1.5rem 1rem;
        vertical-align: top;

        &:first-child {
            padding-left: 2%;
        }
    }

    tbody td {
        padding: 1rem 1rem;
        vertical-align: top;

        &:first-child {
            padding-left: 2%;
        }
    }

    td:has(div.table-row-actions) {
        width: 20px !important;

        div {
            width: 20px;
            margin-right: 1vw;
        }
    }

}