.loginScreen-container {
    display: flex;
    max-width: 1100px;
    margin-bottom: 10%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.loginScreen__aside {
    width: 40%;
    max-width: 500px;
    background-color: #F5F5F5;
    border-radius: 50%;
    aspect-ratio: 1/1;
    display: none;
    margin-right: 2rem;
}

.loginScreen_slider {
    text-align: center;

    h2 {
        font-size: 4.2rem;
        color: $gray-300;
    }

    p {
        width: 90%;
        color: $gray-200;
        font-size: 1.6rem;
        margin: 0 auto;
        margin-top: 1rem;
    }
}

.loginScreen_slider_buttons {
    display: flex;
}

.loginScreen-back {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(236, 236, 236);
    position: absolute;
}

.loginScreen__slider-circles-container {
    position: absolute;
    top: calc(100% + 2rem);
}

@media (min-width:800px) {
    .loginScreen-container {
        justify-self: center;
        align-self: center;
    }

    .loginScreen__aside {
        width: 45%;
        max-width: 500px;
    }
}

@media (min-width:1190px) {
    .loginScreen-container {
        justify-content: space-between;
        align-items: center;
    }

    .loginScreen__aside {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}