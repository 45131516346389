.admin-panel {
    padding: 0 20px;
}

.admin-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    flex-grow: 1;
}

.admin-container {
    background-color: rgb(211, 211, 211);
    flex-grow: 1;
    height: 100%;
    padding-bottom: 4rem;
}

.admin__nav-bar {
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: rgba(214, 214, 214, 0.548);
    width: 100%;
    height: 100%;

    padding-left: 20px;
    padding-right: 20px;

    .admin__links-list {
        list-style: none;
        display: flex;
        justify-content: space-between;

        li a {
            margin: 10px;
        }

        p {
            display: none;
        }
    }

}

.admin-panel__title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0 1rem 0;
    padding-right: 45px;
    position: relative;

    .section-title {
        margin: 0;
        font-size: 2.5rem;
        line-height: 2.7rem;
    }
}

.admin__nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;
    padding: 8px 20px;

    svg {
        color: rgb(124, 124, 124);
    }

    p {
        color: rgb(124, 124, 124);
        font-size: 1.8rem;
        border-radius: 12px;
        margin-left: 10px;
        padding-top: 4px;
    }

}

.admin__nav-link.admin__nav-link-active {

    svg {
        color: black;
        stroke-width: 2.2;
    }

    p {
        font-weight: 500;
        color: black;
    }
}

/* USER PANEL */

.admin__panel-container {
    width: 90%;
    margin: 0 auto;
    // background-color: red;
}

.admin__panel-form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 1.5rem;
    align-items: start; //Antes lo tenia en end, pero lo cambie en start, ya que si tenia un error en el input del medio, las puntas quedaban abajo 
    // y el del medio se tiraba para arriba, no quedando a la misma altura
    margin-bottom: 1.5rem;

    .formLogin__input-comp {
        margin: 0 !important;
    }
}

.hide-component {
    display: none;
}

.input-with-title {
    &>p {
        margin-left: 10px;
        font-size: 1.6rem;
        font-weight: 600;
    }
}

/* Panel de Horarios */
.fc-timegrid-axis {
    background-color: #d9d9d9;
}


.admin-horarios__header {
    display: none;
}

.admin-horarios__header-min {
    display: inline;
}

.fc-col-header-cell {
    font-size: 1.5rem;
}

@media(min-width:480px) {
    .admin-horarios__header {
        display: inline;
    }

    .admin-horarios__header-min {
        display: none;
    }
}


@media(min-width:650px) {
    .fc-col-header-cell {
        font-size: 2rem;
    }
}

/* HORARIOS */

.admin__horarios-calendar-container {
    tbody {
        // background: rgba(241, 241, 241, 0.822);
    }

    .fc-day-today {
        /* Eliminar background amarillento en el dia actual */
        background-color: transparent;
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event {
        /* Eliminar sombra blanca en los eventos */
        box-shadow: none !important;
    }

    .fc-event-main {
        padding: 0 !important;
    }

    .evento-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        h1 {
            font-size: 1.3rem;
            line-height: 1.1rem;
            color: rgba(255, 255, 255, 0.61);
            text-align: center;
        }
    }

}

.admin__horario_especial-container {
    display: grid;
    grid-template-columns: 1fr 6fr;

    .admin__horario_especial_opciones {
        background-color: rgba(194, 194, 194, 0.877);
        padding: 20px 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        width: 100%;

        button:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .admin__horario_especial {
        padding: 20px;
        background-color: rgb(163, 163, 163);
    }
}

.admin-panel__horario-subtitle {
    font-size: 2rem;
}

@media(min-width:690px) {
    .admin-section {
        .admin__nav-bar {
            .admin__links-list {
                p {
                    display: block;
                }
            }
        }
    }
}

@media(min-width:960px) {
    .admin-panel .admin-panel__title-container {
        justify-content: center;
        margin: 3rem 0 3rem 0;

        .admin-panel__btn-filters {
            display: none;
        }

        .section-title {
            font-size: 3.5rem;
            line-height: 3.8rem;
        }
    }

    .hide-component {
        display: block !important;
    }

    .admin-section {
        grid-template-columns: 1fr 7fr;
        grid-template-rows: auto;

        .admin__nav-bar {
            // padding: 40px;
            padding-top: max(20px, 10vh);

            .admin__links-list {
                display: block;

                li {
                    margin-bottom: 2rem;
                }

                p {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 1050px) {
    .admin-section {

        .admin__nav-bar {
            .admin__links-list {

                p {
                    display: block;
                }
            }
        }
    }
}