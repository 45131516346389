.general__section {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: max(100vh, 500px);
    z-index: 2;
}

.home__hero {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 40%;
    min-width: max(60vh, 20vw);
    max-width: 80vh;
    z-index: -1;
}

.home__hero-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20%;
    background-color: rgba(255, 255, 255, 0.842);
    padding: 40px;

    .home__hero-title {
        font-size: 4rem;
        line-height: 3.2rem;
        font-weight: 800;
        color: $darkTextBold;
    }

    hr {
        margin: 20px 0;
        width: 80px;
    }

    .home__hero-description {
        font-size: 1.3rem;
        color: #7C7C7C;
        margin-bottom: 30px;
    }
}

.homeScreen__slider-circles-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: clamp(10px, 5%, 50px);
}

.slider-button {
    background-color: $pink-600;
    width: 14px;
    height: 14px;
    border: none;
    border-radius: 50%;
    transition: 200ms all ease-in-out;

    &:not(:last-child) {
        margin-right: 2rem;
    }

    &.slider-active {
        background-color: $rojo-400;
    }

    &:hover {
        transform: scale(1.3);
    }
}

@media (min-width:600px) {
    .home__hero-container {
        width: 80%;
        // max-width: 500px;
        max-width: 550px;

        .home__hero-title {
            font-size: 6rem;
            line-height: 5rem;
        }

        .home__hero-description {
            font-size: 1.5rem;
        }
    }
}


@media (min-width:1250px) {
    .home__hero-container {
        max-width: 600px;
        width: 60%;
        background-color: transparent;
        padding: 0px;
        margin-bottom: 10%;

        .home__hero-title {
            font-size: 8rem;
            line-height: 6.8rem;
        }

        .home__hero-description {
            font-size: 1.6rem;
        }
    }
}