.formLogin-container {
    width: max-content;
    min-width: 270px;
    max-width: 460px;
}

.formLogin__title {
    font-size: 4rem;
    font-weight: 800;
    color: $gray-300;
    margin-bottom: 10px;
}

.formLogin__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.formLogin__input-container {
    position: relative;
}

.formLogin__input {
    // height: min-content;
    height: fit-content;
}

.formLogin__form.formLogin__input:last-of-type {
    margin-bottom: 10px;
}

.formLogin__input:focus {
    outline: 1px solid rgba(0, 0, 0, 0.178);
}

.formLogin__buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    button:not(:first-child) {
        margin-left: 0;
    }
}

.formLogin__input-comp {
    margin-bottom: 20px;
}

.formLogin__input-comp.input-error {
    margin-bottom: 0px;
}

/* CREAR CUENTA */

.formCreate__nombre-apellido-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
}

@media (min-width:440px) {

    .formCreate__nombre-apellido-container {
        grid-template-columns: 1fr 1fr;
        gap: 1.4rem;

    }

    .formLogin__buttons-container {
        justify-content: end;

        button:not(:first-child) {
            margin-left: clamp(5px, 40%, 40px);
        }
    }
}

@media (min-width:500px) {
    .formLogin__title {
        line-height: 4.7rem;
        font-size: 5rem;
    }
}

@media (min-width:800px) {
    .formLogin__title {
        line-height: 6.2rem;
        font-size: 6.5rem;
    }
}