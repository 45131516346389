.select {
    position: relative;
}

.select::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    height: 7px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #555 transparent transparent transparent;
    transform: translateY(-25%);
    pointer-events: none;
}

select {
    display: grid;
    width: 100%;
    padding: 12px;
    padding-left: 20px;
    border-radius: 20px;
    color: $gray-300;
    border: none;
    outline: none;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    font-size: 1.6rem;
}

select:focus {
    // outline: 1px solid rgba(0, 0, 0, 0.178);
    outline: 1px solid rgba(0, 0, 0, 0.178);
}