.admin-modal-button {
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}


.admin-modal-backdrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
}