.user-screen__form-grid {
    display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 2rem;
    align-items: end;
    margin-bottom: 1.5rem;
}

.user-screen__button-container {
    display: flex;
    justify-content: end;
    margin-top: 1.5rem;

    button:not(:last-child) {
        margin-right: 1rem;
    }
}