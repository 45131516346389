.modal__container {
    background-color: white;
    border-radius: 8px;
    padding: 0 0 0.5rem 0;
    max-height: 90vh;
    overflow-y: auto;
    pointer-events: all;

}

.modal__title-container {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #ffffff;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem 1rem 2.5rem;

    h1 {
        font-size: 2.4rem;
        margin-right: 3rem;
    }

    margin-bottom:1rem;
}

.modal-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    // pointer-events:all;
}

.modal-content {
    padding: 0 2.5rem 2rem 2.5rem;
}

.modal-footer {
    padding: 0 2.5rem;
    padding-bottom: 1.5rem;
}

.modal__btn-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}