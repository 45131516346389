.alert-container {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: -15px;
    border-radius: 4px;
    font-size: 1.5rem;
}

.alert-warning {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
}

.alert-success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    color: #155724;
}

.alert-info {
    background-color: #cce5ff;
    border: 1px solid #b8daff;
    color: #004085;
}

/* ALERT NOTIFICATION - PORTAL */

.alert-notification-warning {
    background-color: #f8d7daec;
    border: 1px solid #ee5e6cbb;
    color: #721c24;
}

.alert-notification-success {
    background-color: #d4eddaec;
    border: 1px solid #5bd477;
    color: #155724;
}

.alert-notification-info {
    background-color: #cce5ffee;
    border: 1px solid #6db1fa;
    color: #004085;
}

@keyframes contarTiempoAlerta {
    0% {
        transform: translateX(-100%);
    }

    13% {
        transform: translateX(-100%);
    }

    87% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes move-in-and-wait {
    0% {
        transform: translateX(200%);
    }

    13% {
        transform: translateX(0%);
    }

    87% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(200%);
    }
}

@keyframes move-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(200%);
    }
}

.alert-notification-wrapper {
    width: 310px;
    height: 100px;
    position: fixed;
    right: 3rem;
    top: 15rem;
    z-index: 9999;
    transform: translateX(200%);
    pointer-events: none;

    &.show {
        animation-name: move-in-and-wait;
        animation-duration: 8000ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        .alert-notification::before {
            animation-name: contarTiempoAlerta;
            animation-duration: 8000ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }

    }

    &:has(.alert-notification:hover) {
        animation-play-state: paused;
    }

    .alert-notification {
        width: 310px;
        height: 100px;
        padding: 20px 33px 20px 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        pointer-events: all;

        svg {
            margin-right: 20x;
        }

        &::before {
            content: "";
            height: 5px;
            width: 100%;
            background-color: rgba(124, 124, 124, 0.781);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9999;
            transform: translateX(-100%);
        }

        &:hover {
            &::before {
                animation-play-state: paused;
            }
        }

        &.hide {
            animation-name: move-out;
            animation-duration: 600ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;

            &::before {
                animation-play-state: paused;
            }
        }
    }

}