.historial_section {}

.historial-container {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    height: 100%;

    thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.historial__back {
    div:nth-child(1) {
        width: 30%;
        min-width: 280px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        left: 5%;
        top: -5%;
    }

    div:nth-child(2) {
        width: 30%;
        min-width: 250px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        right: -8%;
        top: 50vh;
    }

    div:nth-child(3) {
        width: 25%;
        min-width: 140px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #e0e0e059;
        position: absolute;
        left: -10%;
        bottom: -5%;
        // display: none;

    }
}

@media (min-width:600px) {
    .historial__back {
        div:nth-child(1) {
            min-width: 300px;
            max-width: 450px;        }

        div:nth-child(2) {
            min-width: 300px;
            max-width: 450px;
        }

        div:nth-child(3) {
            min-width: 300px;
            max-width: 400px;
        }
    }
}