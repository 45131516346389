.app-nav-bar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: 130px;
    padding: 20px 0;
    z-index: 1000;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

.app-nav-bar__logo {
    width: 90px;
    height: 90px;
}

.app-nav-bar__logo__links-list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    margin-top: 4px;
    justify-content: space-between;
    width: 25%;
    max-width: 340px;
    min-width: min-content;
    display: none;

    li {
        margin: 8px;
        list-style: none;

        &:hover {
            opacity: 0.7;
        }
    }
}

.app-nav-bar__link {
    position: relative;
    text-decoration: none;
    font-weight: 500;

    p {
        color: $colorNavLink;
        font-size: 1.8rem;
        transition: 300ms all ease-in;
    }

    &::before {
        content: "";
        background-color: $mainColor;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateX(-50%) translateY(10px);
        opacity: 0;
        transition: 400ms all ease-in-out;
    }
}

.app-nav-bar__link.app-nav-bar__link-active {
    p {
        transform: translateY(-8px);
        transition: 200ms all ease-in !important;
    }

    &::before {
        content: "";
        background-color: $mainColor;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
        bottom: -2px;
        transition: 200ms all ease-in-out;
    }
}

.app-nav-bar__buttons-list {
    display: flex;

    li {
        list-style: none;
        margin: 0 4px;
    }
}

.btn-icon.navBar-button {
    background-color: $pink-100;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;

    p {
        font-size: 1.2rem;
        font-weight: 400;
        color: $gray-300;
    }
}

.btn-icon.navBar-button:hover {
    opacity: 0.7;
}

nav:has(#nav__servicios.nav__link-active) button {
    background-color: $white-200;
}

// @media (min-width:600px) {
//     .app-nav-bar__logo {
//         width: 120px;
//         height: 120px;
//     }
// }

.app-menu-bar {
    display: flex;
    height: min-content;
    align-items: center;
}

@media (min-width:800px) {
    .app-nav-bar {
        padding: 20px;
    }

    .app-nav-bar__logo {
        width: 120px;
        height: 120px;
    }

    .app-nav-bar__logo__links-list {
        display: flex;
    }

    .app-menu-bar {
        display: none;
    }

}