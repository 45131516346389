.btn-primary {
    background-color: $rojo-400;
    font-size: 1.6rem;
    padding: 1.1rem 2.2rem;
    border-radius: 0.8rem;
    color: $white-100;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

.btn-secondary {
    background-color: #e0e0e0;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.1rem 2.2rem;
    border-radius: 0.8rem;
    color: #202020;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

.btn-primary:hover {
    opacity: 0.8;
    transition: 200ms opacity ease-in-out;
}

.btn-secondary:hover {
    opacity: 0.7;
    transition: 200ms opacity ease-in-out;
}

.btn-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-left: 2rem;

    svg {
        margin-right: 0.6rem;
    }

    &:has(.loader) {
        .loader {
            margin-right: 4px;
        }

        padding: 1.2rem 2.4rem;
    }
}

.btn-icon {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.btn-icon-row {
        p {
            display: none;
        }
    }
}

@media(min-width:960px) {
    .btn-icon.btn-icon-row.navBar-button {
        flex-direction: row !important;
        width: fit-content;
        border-radius: 20px;
        padding: 0 10px;

        p {
            display: block;
            font-size: 1.4rem;
            font-weight: 400;
        }

        svg {
            margin-right: 4px;
        }
    }
}

/* BTN TAMAÑOS */
.btn-small {
    padding: 0.8rem 2rem;
    font-size: 1.5rem;
}

/*  */
.btn-loading {
    .loader {
        top: 2px;
        margin-right: 4px;
    }
}

/* BOTONES DEL TIPO LINK */
.btn-anchor {
    font-size: 1.6rem;
    font-weight: 600;
    background-color: transparent;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

.btn-anchor-primary {
    color: $rojo-400;
}

.btn-anchor-secondary {
    color: $gray-100;
}

.btn-anchor-white {
    color: $white-200;
}

.btn-anchor-sm {
    font-size: 1.4rem;
    font-weight: 400;
}

.btn-anchor-primary:hover,
.btn-anchor-secondary:hover,
.btn-anchor-white:hover {
    text-decoration: underline 1px;
    opacity: 70%;
}

/*  */
button:disabled {
    opacity: 0.7 !important;
    cursor: default;
    filter: brightness(110%);
}

.svg-disabled {
    opacity: 0.5 !important;
    cursor: default !important;
}