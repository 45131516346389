$black-400: #363636;
$black-500: #252525;

$pink-100: #F5F5F5;
$pink-400: #F5EBEA;
$pink-500: #F5E4E2;
$pink-600: #CFC6C5;

$rojo-400: #C55556;

$white-0: #FFFFFF;
$white-100: #f3f3f3;
$white-200: #E6E6E6;

$gray-0: #afafaf;
$gray-100: #7F7F7F;
$gray-200: #5c5c5c;
$gray-300: #363636;



$no-disponible: #c37979;
$poco-disponible: #c2c379;
$mucho-disponible: #79c380;




$darkTextBold: $gray-300;

$mainColor: $rojo-400;
$backgroundColorHome: $pink-400;
$colorNavLink: $black-400;