.nosotros-section {
    width: 80%;
    height: 100%;
    max-width: 900px;

    .nosotros-subtitle {
        font-size: 2.5rem;
        font-weight: 400;
        text-align: center;
        margin-bottom: 3rem;
        color: $darkTextBold;
    }

    .nosotros-container {
        margin: 2rem 0;
        column-gap: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .nosotros-contacto-container {
            width: 100%;
            margin-bottom: 3rem;

            .nosotros-contacto-data {
                h3 {
                    font-size: 1.8rem;
                    color: $darkTextBold;
                }

                p {
                    margin-top: 4px;
                    line-height: 2rem;
                    font-size: 1.7rem;
                    color: $gray-200;
                }
            }

            .nosotros-contacto-data:not(:last-child) {
                margin-bottom: 1.8rem;
            }

        }

        .nosotros-mapa {
            max-width: 500px;
            width: 100%;
            min-width: 270px;
            height: 300px;
        }
    }

    .nosotros-instagram-container {
        margin: 0 auto;
        margin-top: 3.5rem;
        width: auto;
        flex-grow: 1;

        h4 {
            font-size: 2rem;
            font-weight: 400;
            text-align: center;
        }

        .nosotros-instagram-galery {
            display: flex;
            margin: 0 auto;
            margin-top: 1rem;
            justify-content: space-between;
            flex-wrap: wrap;
            column-gap: 1.5rem;
            margin-bottom: 2rem;

            .nosotros-imagen:not(:last-child) {
                margin-right: 4%;
            }

            .nosotros-imagen {
                box-shadow: -1px 1px 15px 0px rgb(0 0 0 / 30%);
                width: 100%;
                background-color: rgb(27, 27, 27);
                border-radius: 0.8rem;
                aspect-ratio: 1/1;

            }

            .nosotros-imagen-container {
                width: 21%;
                transition: transform 300ms ease-in-out;
                opacity: 0.8;
                min-width: 120px;

                &:hover {
                    opacity: 1;
                    transition: transform 200ms ease-in-out;
                }
            }
        }

    }




}

.nosotros-imagen-container:nth-child(4) {
    display: none;
}

.nosotros-imagen-container:nth-child(3) {
    display: none;
}



@media (min-width:600px) {
    .nosotros-section {
        .nosotros-subtitle {
            font-size: 3rem;
        }

        .nosotros-container {
            flex-direction: row;

            .nosotros-contacto-container {
                margin-bottom: 0rem;
                width: fit-content;

            }

            .nosotros-mapa {
                width: 70%;
            }
        }
    }
}

@media (min-width:490px) {
    .nosotros-imagen-container:nth-child(3) {
        display: block;
    }
}

@media (min-width:660px) {
    .nosotros-imagen-container:nth-child(4) {
        display: block;
    }
}