.servicios__section {
    position: relative;
    z-index: 1;

    .container {
        margin-top: 3rem;
    }
}

.servicios__tables-container {
    position: relative;
    display: grid;
    max-width: 1100px;
    margin: 0 auto;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.servicios__tabla {
    tr td {
        color: $gray-300;
        font-weight: 800;
        padding-right: 20px;
    }

    .main-td {
        color: $gray-100;
        flex-grow: 1;
        font-weight: 400;
        width: 90%;
        padding-left: 20px;
        padding-right: 1.2rem;
    }
}

.servicios__title-hero {
    position: absolute;
    font-weight: 900;
    font-size: 12rem;
    width: 100vh;
    top: 0;
    right: 96%;
    color: #8d8d8d6e;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    z-index: -1;
}

@media (min-width:900px) {
    .servicios__tables-container {
        display: grid;
        max-width: 1100px;
        margin: 0 auto;
        grid-template-columns: 3fr 2fr;
        gap: 2rem;
    }
}