.pagination-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 0%));
    column-gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    &.active {
        background-color: $rojo-400;
        color: $white-100;
        border: none;
        font-weight: 500;
    }
}