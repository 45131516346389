:root {
  --fc-border-color: #ababab !important;
}


* {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  // color: $fontGeneralColor;
}

p {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #363636;
}

html {
  font-size: 62.5%;
  // scroll-behavior: smooth;
}

.general-container {
  padding-top: 100px;
  min-height: 100vh;
}

.section-title {
  font-size: 3.5rem;
  margin: 2rem 0 1rem 0;
  opacity: 0.9;
}

.container {
  height: 100%;
  width: 90%;
  min-width: 270px;
  max-width: 1500px;
  margin: 0 auto;
}



.container-y-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-fluid {
  width: 100%;
}

.App {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-color: $backgroundColorHome;
}

@keyframes leftEntranceOpacity {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rightEntranceOpacity {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes growEntranceOpacity {
  0% {
    opacity: 0;
    scale: 0;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

.initialOpacity {
  opacity: 0;
}

@keyframes entranceOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.initialOpacity {
  opacity: 0;
}

.growEntranceOpacity {
  animation-name: growEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.entranceOpacity {
  animation-name: entranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.leftEntranceOpacity {
  animation-name: leftEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

.rightEntranceOpacity {
  animation-name: rightEntranceOpacity;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-delay: 500ms;
}

/* INPUTS */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

input {
  border: none;
  width: 100%;
  font-size: 1.6rem;
  padding: 12px 12px 12px 24px;
  border-radius: 20px;
  color: $black-400;
}

input::placeholder {
  color: $gray-0;
}

.input-error {

  input,
  select,
  textarea {
    outline: 1px solid $rojo-400 !important;
    margin-bottom: 2px !important;
  }

  p {
    margin-bottom: 10px;
    margin-left: 12px;
  }
}

.input-success {

  input,
  select,
  textarea {
    outline: 1px solid rgb(70, 165, 98) !important;
  }
}

.input-disabled {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input,
  textarea {
    -webkit-box-shadow: 0 0 0 30px rgb(233, 233, 233) inset;
    outline: 1px solid rgb(100, 100, 100) !important;
    background-color: rgba(192, 192, 192, 0.363) !important;
    opacity: 0.75;
  }
}

.input-toggle-visibility-container {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%)
}

.formLogin__input-error-text {
  font-size: 1.5rem;
  color: $rojo-400;
}

.cruz-icon:hover {
  opacity: 0.7;
  cursor: pointer;

}


/* Textarea */
textarea {
  border: none;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 12px 12px 12px 24px;
  border-radius: 20px 20px 0 20px;
  font-size: 1.6rem;
  color: #363636;

  &:focus {
    -webkit-box-shadow: 0 0 0 30px rgb(233, 233, 233) inset;
    outline: 1px solid rgba(0, 0, 0, 0.178);
    background-color: rgba(192, 192, 192, 0.363) !important;
    opacity: 0.75;
  }
}

/* MARGENES */
.mb-1 {
  margin-bottom: 0.6rem;
}

.mb-2 {
  margin-bottom: 1.4rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

// 

.mt-2 {
  margin-top: 1.4rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

/* IMPUTS RADIO */
input[type=radio] {
  height: 14px;
  width: 14px;
}

input[type="radio"]:checked {
  width: 100%;
  height: 100%;
  accent-color: $rojo-400;
  height: 14px;
}

/* ALERTS */
.alert {
  width: 100%;
  border-radius: 0.9rem;
  padding: 2rem;
  font-size: 1.6rem;
}

.alert-danger {
  background-color: #ffc4c4a4;
  outline: 1px solid $rojo-400 !important;
  color: $black-500;
}

.alert-success {
  background-color: #c9ffc4a4;
  outline: 1px solid rgb(70, 165, 98) !important;
  color: $black-500;
}


/* OTROS */
.cartel-importante {
  display: flex;
  align-items: center;

  svg {
    margin-right: 3px;
  }
}

@media (min-width:800px) {
  .container {
    width: 80%;
  }

  .general-container {
    padding-top: 130px;
  }
}