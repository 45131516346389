.admin-panel-tablero-container {
    display: flex;
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    div:not(:last-child) {
        margin-right: 2rem;
    }
}

.admin-panel-tablero-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    min-width: 7rem;

    h3 {
        font-size: 2.5rem;
        margin: 0;
        padding: 0;
    }
}

.admin-panel-tablero-main-detail {
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 75%;
}